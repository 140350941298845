export const handledRpc =
  <T extends (...args: any[]) => Promise<void>>(
    fn: T,
    sentryExtras?: Record<string, any>,
  ) =>
  async (...args: Parameters<T>) => {
    try {
      await fn(...args)
    } catch (error) {
      const fnExtras = args.reduce((res, arg, index) => {
        try {
          const value = JSON.stringify(arg)
          return { ...res, [`arg${index}`]: value }
        } catch {
          return res
        }
      }, {})

      const data: Record<string, any> = {
        rpc: fn.name || 'anonymous function',
        service: 'unknown',
        ...fnExtras,
        ...sentryExtras,
      }

      console.error(data, error)
    }
  }
